import { Controller } from "stimulus"
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [ "filter" ]

  connect() {
    this.filter = debounce(this.filter, 1000)
  }

  get params() {
    let filters = []

    this.filterTargets.forEach(filter => {

      if (filter.dataset.filterAttr) {

        switch (filter.tagName.toLowerCase()) {

          case "input":
            switch (filter.type.toLowerCase()) {

              case "checkbox":
              case "radio":
                const value = filter.checked ? filter.dataset.valueOn : filter.dataset.valueOff

                if (value !== undefined)
                  filters.push(`${filter.dataset.filterAttr}=${value}`);
                break

              default:
                if (filter._flatpickr) {
                  const fp = filter._flatpickr

                  switch (fp.config.mode) {
                    case "single":
                      if (fp.selectedDates.length == 1) {
                        const date = fp.formatDate(fp.selectedDates[0], "Y-m-d")

                        filters.push(`${filter.dataset.filterAttr}=${date}`)
                      }
                      break

                    case "range":
                      if (fp.selectedDates.length == 2) {
                        const date_start = fp.formatDate(fp.selectedDates[0], "Y-m-d")
                        const date_end = fp.formatDate(fp.selectedDates[1], "Y-m-d")

                        filters.push(`${filter.dataset.filterAttr}..[]=${date_start}`)
                        filters.push(`${filter.dataset.filterAttr}..[]=${date_end}`)
                      }
                      break

                    case "multiple":
                      if (fp.selectedDates.length > 0) {
                        fp.selectedDates.forEach(fp_date => {
                          const date = fp.formatDate(fp_date, "Y-m-d")

                          filters.push(`${filter.dataset.filterAttr}[]=${date}`)
                        })
                      }
                      break
                  }

                } else {
                  if (filter.value !== "")
                    filters.push(`${filter.dataset.filterAttr}=${filter.value}`);
                }
            }
            break
        }
      }
    })

    return filters.join("&")
  }

  filter(event) {
    const el = event.target
    if (el._flatpickr && el._flatpickr.isOpen)
      return;

    const url = `${window.location.pathname}?${this.params}`
    if (url !== `${window.location.pathname}?${window.location.search}`) {
      Turbolinks.clearCache()
      Turbolinks.visit(url)
    }
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sheetContainer", "groupBy" ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.loadSheets()
    }
  }

  loadSheets() {
    this.sheetContainerTargets.forEach(sheet => {
      this.loadSheet(sheet)
    })
  }

  refreshSheet(event) {
    const sheet = document.querySelector(event.currentTarget.dataset.sheetTarget)
    if (sheet)
      this.loadSheet(sheet);
  }

  loadSheet(sheet, date = undefined) {
    let lessonId = sheet.dataset.lesson || ""
    let groupBy = ""
    const weeks = sheet.dataset.weeks || ""
    const includeInactive = sheet.dataset.includeInactive || false
    const navigation = sheet.dataset.navigation || false

    const dateFilter = document.querySelector(sheet.dataset.dateFilter)
    if (!date) {
      date = sheet.dataset.date || ""

      if (dateFilter)
        date = dateFilter.value || "";
    } else {
      if (dateFilter)
        dateFilter.value = date;
    }

    const lessonFilter = document.querySelector(sheet.dataset.lessonFilter)
    if (lessonFilter)
      lessonId = lessonFilter.value;

    const groupByEl = this.groupByTargets.find(el => el.classList.contains("is-selected"))
    if (groupByEl)
      groupBy = groupByEl.dataset.groupBy || "";

    fetch(`/attendance/sheet?date=${date}&lesson_id=${lessonId}&weeks=${weeks}&group_by=${groupBy}&includeInactive=${includeInactive}&navigation=${navigation}`)
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then(content => {
        sheet.innerHTML = content
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      })
  }

  navigate(event) {
    event.preventDefault()
    const sheet = document.querySelector(event.currentTarget.dataset.sheetTarget)
    const date = event.currentTarget.dataset.date

    this.loadSheet(sheet, date)
  }

  toggle(event) {
    event.preventDefault()
    const el = event.currentTarget

    const studentId = el.dataset.student
    const date = el.dataset.date
    let lessonId = ""

    let status
    if (el.classList.contains("present")) {
      status = "absent"
      lessonId = el.dataset.lesson
    } else {
      status = "present"
    }

    const token = document.getElementsByName("csrf-token")[0].content

    fetch(`/attendance/update/${studentId}/${date}/${status}/${lessonId}`, {
      headers: { "X-CSRF-Token": token },
      method: 'PATCH',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error()
        } else {
          const dates = document.querySelectorAll(`.attendance-sheet .date[data-student="${studentId}"][data-date="${date}"]`)

          dates.forEach(el => {
            if (lessonId == "" || el.dataset.lesson == lessonId) {
              el.classList.remove("present", "absent", "not-set")
              el.classList.add(status)
            }
          })
          // TODO show notification success
          // TODO force turbolinks to reload
        }
      })
      .catch(error => {
        // TODO show notificatio error
        console.error('There has been a problem with your fetch operation:', error);
      })
  }

}




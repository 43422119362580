import { Controller } from "stimulus"
import { showNotification } from "controllers/theme_controller.js"

import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "cardContainer", "cardMessages", "paymentSubmit", "totalAmount" ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {

      document.addEventListener("turbolinks:load", () => {

        this.stripe = window.stripe

        if (this.cardContainerTarget) {
          this.card = this.stripe.elements().create("card")
          this.card.mount(this.cardContainerTarget)

          if (this.cardMessagesTarget) {
            card.addEventListener("change", event => {
              if (event.error) {
                this.cardMessagesTarget.textContent = event.error.message
              } else {
                this.cardMessagesTarget.textContent = ""
              }
            })
          }

        }

      }) // turbolinks:load

    } // data-turbolinks-preview

  }

  submitForm(event) {
    this.paymentSubmitTarget.classList.add("is-loading")

    const amount = parseFloat(this.totalAmountTarget.value) * 100

    let response = fetch(`/stripe/prepare_payment/${amount}`)
      .then(response => response.json())
      .then(data => {

        if (data.client_secret) {
          this.clientSecret = data.client_secret

          Rails.fire(this.formTarget, 'submit')

        } else {
          showNotification(
            `${data.message}. <strong>No charges has been done!</strong>`,
            "warning",
            ".student-payments-block",
            "beforeend",
            "is-light",
            5000
          )

          this.cardMessagesTarget.textContent = result.error.message
          this.paymentSubmitTarget.classList.remove("is-loading")
        }
      })
  }

  success() {
    const [data, status, xhr] = event.detail

    if (xhr.status == 200) {

      this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.card,
          },
        }
      ).then(result => {

        if (result.paymentIntent) {
          // Handle result.paymentIntent

          if (data.hasOwnProperty("notice")) {
            showNotification(
              data.notice,
              "success",
              ".student-payments-block",
              "beforeend",
              "is-light"
            )
          }

          this.paymentSubmitTarget.classList.remove("is-loading")
          this.paymentSubmitTarget.setAttribute("disabled", true)

        } else {

          let message = "Oops! Something went wrong. Please, try again."
          if (data.hasOwnProperty("notice"))
            message = result.error.message;

          showNotification(
            `${message} <strong>No charges has been done!</strong>`,
            "danger",
            ".student-payments-block",
            "beforeend",
            "is-light",
            9999
          )

          if (data.hasOwnProperty("transactions")) {
            const token = document.querySelector("[name=csrf-token]").getAttribute("content")

            let error = `[${result.error.code}] ${result.error.message}`

            data.transactions.forEach(id => {
              fetch(`/transactions/${id}.js`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  "X-CSRF-Token": token
                },
                body: JSON.stringify( { notes: error })
              })
            })
          }

          this.paymentSubmitTarget.classList.remove("is-loading")
        }
      })

    }
  }

  error(event) {
    const [data, status, xhr] = event.detail

    let message = "Oops! Something went wrong. Please, try again."
    if (data.hasOwnProperty("notice"))
      message = data.notice;

    showNotification(
      `${message} <strong>No charges has been done!</strong>`,
      "warning",
      ".student-payments-block",
      "beforeend",
      "is-light",
      9999
    )

    this.paymentSubmitTarget.classList.remove("is-loading")
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "recordsSheetContainer",
    "recordsNavigatePrev",
    "recordsNavigateNext"
  ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.loadSheets()
    }
  }

  loadSheets() {
    this.recordsSheetContainerTargets.forEach(sheet => {
      this.loadSheet(sheet)
    })
  }

  refreshSheet(event) {
    const sheet = document.querySelector(event.currentTarget.dataset.sheetTarget)
    if (sheet)
      this.loadSheet(sheet);
  }

  loadSheet(sheet, date = undefined) {
    const studentId = sheet.dataset.student || ""
    const weeks = sheet.dataset.weeks || ""
    const navigation = sheet.dataset.navigation || false

    if (studentId) {

      const dateFilter = document.querySelector(sheet.dataset.dateFilter)
      if (!date) {
        date = sheet.dataset.date || ""

        if (dateFilter)
          date = dateFilter.value || "";
      } else {
        if (dateFilter)
          dateFilter.value = date;
      }

      const lessonFilter = document.querySelector(sheet.dataset.lessonFilter)
      if (lessonFilter)
        lessonId = lessonFilter.value;

      fetch(`/students/${studentId}/records_sheet?date=${date}&weeks=${weeks}&navigation=${navigation}`)
        .then(response => {
          if (response.ok) {
            return response.text()
          } else {
            throw new Error(response.statusText)
          }
        })
        .then(content => {
          sheet.innerHTML = content
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        })

    }
  }

  recordsNavigate(event) {
    event.preventDefault()
    const sheet = document.querySelector(event.currentTarget.dataset.sheetTarget)
    const date = event.currentTarget.dataset.date

    this.loadSheet(sheet, date)
  }

}




import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
  }

  navigate(event) {
    const page = event.currentTarget.dataset.page

    if (page) {
      const params = new URLSearchParams(window.location.search)
      params.delete("page")
      params.append("page", page)

      const url = `${window.location.pathname}?${params.toString()}`

      Turbolinks.clearCache()
      Turbolinks.visit(url)
    }
  }

}




import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
    }
  }

  navigate(event) {
    event.preventDefault()
    const sheet = document.querySelector(event.currentTarget.dataset.sheetTarget)
    const date = event.currentTarget.dataset.date

    this.loadSheet(sheet, date)
  }

  toggle(event) {
    event.preventDefault()
    const el = event.currentTarget

    const studentId = el.dataset.student
    const date = el.dataset.date
    const lessonId = el.dataset.lesson

    let paid
    if (el.classList.contains("paid")) {
      paid = false
    } else if (el.classList.contains("not-paid")) {
      paid = true
    }

    const token = document.getElementsByName("csrf-token")[0].content

    fetch(`/students/${studentId}/update_payment/${date}/${paid}/${lessonId}`, {
      headers: { "X-CSRF-Token": token },
      method: 'PATCH',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error()
        } else {
          el.classList.remove("paid", "not-paid")
          el.classList.add(paid ? "paid" : "not-paid")

          // TODO show notification success
          // TODO force turbolinks to reload
        }
      })
      .catch(error => {
        // TODO show notificatio error
        console.error('There has been a problem with your fetch operation:', error);
      })
  }

}




import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "studentPaymentsContainer", "studentSelector"
  ]
  
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.selectStudent()
    }
  }
  
  getStudentsPaymentBlock(studentId) {
    fetch(`/students/${studentId}/payments_block`)
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then(content => {
        this.studentPaymentsContainerTarget.innerHTML = content
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      })
  }
  
  selectStudent() {
    const listOptions = document.getElementById(this.studentSelectorTarget
      .getAttribute("list")).querySelectorAll("option")
    const optionSelected = Array.from(listOptions)
      .filter(el => el.innerText == this.studentSelectorTarget.value) || []
    
    if (optionSelected.length > 0) {
      const studentId = optionSelected[0].dataset.value
      
      if (studentId) {
        this.getStudentsPaymentBlock(studentId)
      } else {
        this.resetStudentPaymentForm()
      }
    } else {
      this.resetStudentPaymentForm()
    }
  }
  
  resetStudentPaymentForm(event) {
    if (event)
      event.preventDefault();
    
    this.studentPaymentsContainerTarget.innerHTML = ""
  }  
}




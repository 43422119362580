import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addAction", "fieldTemplate"]

  add(event) {
    if (event) {
      event.preventDefault()
    }

    const content = this.fieldTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addActionTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    event.preventDefault()
    const item = event.target.closest(".fields")
    if (item.classList.contains("new")) {
      item.remove()
    } else {
      item.querySelector("input[name*='_destroy']").value = true
      item.classList.add("is-hidden")
    }
  }
}




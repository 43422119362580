import { Controller } from "stimulus"

import Iconify from "@iconify/iconify/dist/iconify.without-observer"
import { DataTable } from "simple-datatables"

export default class extends Controller {
  static targets = [
    "mainAside",
    "mainNavbarBurger",
    "mainNavbarBurgerMobile",
    "mainNavbarMenuToggle",
    "mainNavbarMenu",
    "mainNavbarNotificationMenu",
    "asideSubmenu",
  ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      Iconify.scanDOM()

      document.querySelectorAll(".datatable").forEach(el => {
        let dataTable = new DataTable(el, {
          layout: {
              top: "{search}{select}",
              bottom: "{info}{pager}"
          },
          labels: {
              perPage: "{select}",
              noRows: "Nothing found :/",
              info: "Showing {start} to {end} of {rows} rows",
          }
        })

        dataTable.table.classList.add("table", "is-hoverable", "is-fullwidth")
      })

      document.addEventListener("turbolinks:load", () => {

        // Move caret at the end of autofocus inputs
        // It can fails!
        try {
          document.querySelectorAll("input[autofocus]").forEach(el => {
            el.setSelectionRange(el.value.length, el.value.length)
          })
        } catch {}

        // Notifications
        document.querySelectorAll(".notification .delete").forEach(deleteEl => {
          const notificationEl = deleteEl.parentNode
          deleteEl.addEventListener("click", () => {
            removeNotification(notificationEl)
          })
        })

        // File inputs
        const fileInputs = document.querySelectorAll(".file.has-name input[type=file]")
        fileInputs.forEach(fileInput => {
          fileInput.addEventListener("change", () => {
            if (fileInput.files.length > 0) {
              const fileName = fileInput.closest(".file.has-name").querySelector(".file-name")
              fileName.textContent = fileInput.files[0].name
            }
          })
        })

        // Tabs
        this.refreshTabs()

        // Masonry
        this.refreshMasonry()
      })

      document.addEventListener("theme:classesToggled", () => this.refreshMasonry())

      const observer = new MutationObserver(mutations => {
        let masonryRefreshed = false

        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach(el => {

            // Notifications dinamically added
            if (el.classList && el.classList.contains("notification")) {
              // Delete button
              const deleteEl = el.querySelector(".delete")
              if (deleteEl) {
                deleteEl.addEventListener("click", () => {
                  removeNotification(el)
                })
              }
            }
          })

          // Refresh masonry height at any mutation
          // when the element is inside a masonry
          if (!masonryRefreshed && mutation.target.closest(".masonry")) {
            masonryRefreshed = true
            this.refreshMasonry()
          }
        })
      })
      observer.observe(document.querySelector("body"), { childList: true, subtree: true })
    }
  }

  getAttrValues(content) {
    if (content) {
      return content.split(",").map(x => x.trim())
    }
  }

  toggle(targets, classNames, force) {
    let className
    targets.forEach((target, i) => {
      className = undefined
      if (classNames.length == 1) {
        className = classNames[0]
      } else {
        className = classNames[i]
      }
      if (className) {
        if (force === undefined) {
          document.querySelectorAll(target).forEach(el => el.classList.toggle(className))
        } else {
          document.querySelectorAll(target).forEach(el => el.classList.toggle(className, force))
        }
      }
    })

    const event = new Event("theme:classesToggled", { targets: targets })
    document.dispatchEvent(event)
  }

  toggleClass(event, force) {
    const toggleTargets = this.getAttrValues(event.currentTarget.dataset.toggleTargets)
    const toggleClasses = this.getAttrValues(event.currentTarget.dataset.toggleClasses)
    this.toggle(toggleTargets, toggleClasses)
  }

  removeClass(event) {
    const removeTargets = this.getAttrValues(event.currentTarget.dataset.removeTargets)
    const removeClasses = this.getAttrValues(event.currentTarget.dataset.removeClasses)
    this.toggle(removeTargets, removeClasses, false)
  }

  addClass(event) {
    const addTargets = this.getAttrValues(event.currentTarget.dataset.addTargets)
    const addClasses = this.getAttrValues(event.currentTarget.dataset.addClasses)
    this.toggle(addTargets, addClasses, true)
  }

  swapBurger() {
    this.mainNavbarBurgerTargets.forEach(el => el.classList.toggle("is-hidden"))
  }

  toggleAside() {
    this.mainNavbarBurgerTargets.forEach(el => el.classList.toggle("is-hidden"))
    this.mainAsideTarget.classList.toggle("is-collapsed")
    document.body.classList.toggle("has-aside-collapsed")
  }

  toggleAsideMobile() {
    this.mainNavbarBurgerMobileTargets.forEach(el => el.classList.toggle("is-hidden"))
    this.mainAsideTarget.classList.toggle("is-active")
    document.body.classList.toggle("has-aside-active")
  }

  closeAllNavbarMenus() {
    document.querySelectorAll(".navbar-menu").forEach(el => {
      el.classList.remove("is-active")
    })
  }
  toggleNavbarMenu() {
    this.closeAllNavbarMenus()
    document.getElementById("navbar_menu").classList.toggle("is-active")
  }
  toggleNavbarNotificationMenu() {
    this.closeAllNavbarMenus()
    document.getElementById("notification_menu").classList.toggle("is-active")
  }

  toggleAsideSubmenu() {
    this.asideSubmenuTarget.classList.toggle("is-active")
  }

  redirectTo() {
    const el = event.currentTarget
    const location = el.dataset.location
    console.log(el, location)
    if (location) {
      window.location.href = location
    }
  }

  toggleDisabled(event, status = undefined) {
    const targets = document.querySelectorAll(event.currentTarget.dataset.disableTargets)
    targets.forEach(target => {
      if (status !== undefined) {
        target.disabled = status

        if (status === true) {
          target.setAttribute("disabled", true)
        } else {
          target.removeAttribute("disabled")
        }
      } else {
        target.disabled = !target.disabled

        if (target.disabled === true) {
          target.setAttribute("disabled", true)
        } else {
          target.removeAttribute("disabled")
        }
      }
    })
  }

  disable(event) {
    this.toggleDisabled(event, true)
  }

  enable(event) {
    this.toggleDisabled(event, false)
  }

  refreshMasonry() {
    let numColumns, numColumnsClass, columnsHeight
    document.querySelectorAll(".masonry").forEach(masonryEl => {
      numColumnsClass = masonryEl.getAttribute("class").match(/.*is-(\d).*/)
      numColumns = (numColumnsClass) ? numColumnsClass[1] : 2

      columnsHeight = {};
      masonryEl.querySelectorAll(".masonry-item").forEach((itemEl, i) => {
        columnsHeight[i % numColumns] = (columnsHeight[i % numColumns] || 0) + itemEl.offsetHeight
      })

      masonryEl.style.height = Object.values(columnsHeight).reduce((a,b) => a > b ? a : b).toString() + "px"
    })
  }

  refreshTabs(tabsEl) {
    let allTabs, activeTabs

    if (tabsEl) {
      allTabs = tabsEl.querySelectorAll("li[data-tab-target]")
      activeTabs = tabsEl.querySelectorAll("li.is-active[data-tab-target]")
    } else {
      allTabs = document.querySelectorAll(".tabs li[data-tab-target]")
      activeTabs = document.querySelectorAll(".tabs li.is-active[data-tab-target]")
    }

    allTabs.forEach(el => document.querySelector(el.dataset.tabTarget).classList.add("is-hidden"))
    activeTabs.forEach(el => document.querySelector(el.dataset.tabTarget).classList.remove("is-hidden"))
  }

  selectTab(event) {
    const tabEl = document.querySelector(`li[data-tab-target="${event.currentTarget.dataset.tabTarget}"]`)

    if (tabEl) {
      const tabsEl = tabEl.closest(".tabs")

      if (tabsEl)
        tabsEl.querySelectorAll("li").forEach(el => el.classList.remove("is-active"));

      tabEl.classList.add("is-active")

      this.refreshTabs(tabsEl)
    }
  }

  selectOption(event) {
    event.preventDefault()

    const optionEl = event.currentTarget
    const optionsGroupEl = optionEl.closest(".field, .buttons")

    if (optionsGroupEl)
      optionsGroupEl.querySelectorAll(".button").forEach(el => el.classList.remove("is-selected"));

    optionEl.classList.add("is-selected")
    optionEl.blur()
  }

  clearInput(event) {
    let el

    if (event.currentTarget.dataset.target) {
      const targetEl = document.querySelector(event.currentTarget.dataset.target)

      if (targetEl)
        el = targetEl;

    } else {
      const inputEl = event.currentTarget.previousSibling

      if (inputEl)
        el = inputEl;
    }

    if (el) {
      el.value = ""
      el.focus()

      const eventName = event.currentTarget.dataset.clearEvent
      fireEvent(el, eventName || "change");
    }
  }

}

export const showNotification = (message, type, selector, position, classNames = "", duration = undefined) => {
  const id = `notification-${Date.now()}`
  const html = `
    <div id="${id}" class="notification is-${type} ${classNames}">
      <button class="delete"></button>
      ${message}
    </div>
  `.trim()

  document
    .querySelector(selector)
    .insertAdjacentHTML(position, html)

  if (duration) {
    const notificationEl = document.getElementById(id)
    setTimeout(() => removeNotification(notificationEl), duration)
  }
}

export const removeNotification = (notificationEl) => {
  notificationEl.addEventListener(
    "transitionend",
    () => notificationEl.parentNode.removeChild(notificationEl)
  )

  notificationEl.style.opacity = 0
}

export const fireEvent = (el, eventName) => {
  const event = new CustomEvent(eventName, { bubbles: true, cancelable: true })
  el.dispatchEvent(event)
}

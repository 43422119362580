import { Controller } from "stimulus"
import { showNotification } from "controllers/theme_controller.js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "originId", "student", "accountSelected", "categoriesSelected", "periodSelected", "lessonsSelected",
    "lessonsAmount", "discountValue", "discountAmount", "feesAmount", "totalAmount",
    "feesTagsContainer", "lessonTagsContainer", "discountTagsContainer", "lastLessonsPaidContainer",
    "feeTag", "periodTag", "lessonTag", "discountTag", "accountTag",
    "totalsContainer", "totalsLessonsAmount", "totalsDiscountAmount", "totalsFeesAmount", "totalsTotalAmount",
    "cardContainer", "cardElement", "cardMessages", "paymentSubmit",
  ]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.getRates()

      document.addEventListener("turbolinks:load", () => {
        if (this.hasPaymentSubmitTarget) {
          this.payment_gateway = this.paymentSubmitTarget.dataset.gateway

          switch (this.payment_gateway) {
            case "stripe":
              this.setupStripe()
              break
          }
        } else {
          this.payment_gateway = null
        }
      })

    }
  }

  success(event) {
    const [data, status, xhr] = event.detail

    if (xhr.status == 200) {
      switch (this.payment_gateway) {

        case "stripe":

          this.stripe.confirmCardPayment(
            this.clientSecret,
            {
              payment_method: {
                card: this.card,
              },
            }
          ).then(result => {

            if (result.paymentIntent) {
              // Handle result.paymentIntent

              if (data.hasOwnProperty("notice")) {
                showNotification(
                  data.notice,
                  "success",
                  ".student-payments-block",
                  "beforeend",
                  "is-light"
                )
              }

              this.paymentSubmitTarget.classList.remove("is-loading")
              this.paymentSubmitTarget.setAttribute("disabled", true)

            } else {

              let message = "Oops! Something went wrong. Please, try again."
              if (data.hasOwnProperty("notice"))
                message = result.error.message;

              showNotification(
                `${message} <strong>No charges has been done!</strong>`,
                "danger",
                ".student-payments-block",
                "beforeend",
                "is-light",
                9999
              )

              if (data.hasOwnProperty("transactions")) {
                const token = document.querySelector("[name=csrf-token]").getAttribute("content")

                let error = `[${result.error.code}] ${result.error.message}`

                data.transactions.forEach(id => {
                  fetch(`/transactions/${id}.js`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      "X-CSRF-Token": token
                    },
                    body: JSON.stringify( { notes: error })
                  })
                })
              }

              this.paymentSubmitTarget.classList.remove("is-loading")
            }
          })
          break

        default:
          if (data.hasOwnProperty("notice")) {
            showNotification(
              data.notice,
              "success",
              ".student-payments-block",
              "beforeend",
              "is-light"
            )
          }
      }

    }
  }

  error(event) {
    const [data, status, xhr] = event.detail

    let message
    if (data.hasOwnProperty("notice")) {
      message = data.notice

    } else {
      message = "Oops! Something went wrong. Please, try again."
    }

    showNotification(
      `${message} <strong>No charges has been done!</strong>`,
      "warning",
      ".student-payments-block",
      "beforeend",
      "is-light",
      9999
    )

    this.paymentSubmitTarget.classList.remove("is-loading")
  }

//   complete(event) {
//     const [xhr, status] = event.detail
//
//     if (xhr.status == 200) {
//       this.paymentSubmitTarget.classList.remove("is-loading")
//       this.paymentSubmitTarget.setAttribute("disabled", true)
//     }
//   }

  getRates() {
    fetch(`/rates.json`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then(content => {
        if (Array.isArray(content))
          this.rates = content;
      })
      .catch(error => {
        console.error('There has been a problem:', error)
      })
  }

  setupStripe() {
    this.stripe = window.stripe || null

    if (this.stripe && this.hasCardElementTarget) {
      this.card = this.stripe.elements().create("card")
      this.card.mount(this.cardElementTarget)

      if (this.cardMessagesTarget) {
        card.addEventListener("change", event => {
          if (event.error) {
            this.cardMessagesTarget.textContent = event.error.message
          } else {
            this.cardMessagesTarget.textContent = ""
          }
        })
      }
    }
  }

  submitForm(event) {
    const formEl = document.querySelector(event.currentTarget.dataset.form)

    if (formEl) {
      this.paymentSubmitTarget.classList.add("is-loading")

      if (this.stripe) {
        this.submitFormStripe(formEl)
      } else {
        Rails.fire(formEl, 'submit')
      }
    }
  }

  submitFormStripe(formEl) {
    const amount = parseFloat(this.totalAmountTarget.value) * 100

    let response = fetch(`/stripe/prepare_payment/${amount}`)
      .then(response => response.json())
      .then(data => {

        if (data.client_secret) {
          this.clientSecret = data.client_secret

          Rails.fire(formEl, 'submit')

        } else {
          showNotification(
            `${data.message}. <strong>No charges has been done!</strong>`,
            "warning",
            ".student-payments-block",
            "beforeend",
            "is-light",
            5000
          )

          this.cardMessagesTarget.textContent = result.error.message
          this.paymentSubmitTarget.classList.remove("is-loading")
        }
      })
  }

  selectFee(event) {
    event.preventDefault()

    const feeEl = event.currentTarget
    feeEl.classList.toggle("is-selected")
    feeEl.blur()

    this.updateCategoriesSelected()
    this.calculateTotalAmounts()
  }

  selectPeriod(event) {
    event.preventDefault()

    const periodEl = event.currentTarget
    this.periodTagTargets.forEach(tag => {
      if (tag === periodEl) {
        periodEl.classList.toggle("is-selected")
        periodEl.blur()
      } else {
        tag.classList.remove("is-selected")
      }
    })

    const period = periodEl.dataset.period
    const isPeriodSelected = periodEl.classList.contains("is-selected")

    this.lessonTagTargets.forEach(tag => tag.classList.toggle("is-selected", isPeriodSelected))

    if (isPeriodSelected) {
      this.periodSelectedTarget.value = period
      this.lessonTagsContainerTarget.classList.remove("is-hidden")
      if (this.hasDiscountTagsContainerTarget)
        this.discountTagsContainerTarget.classList.remove("is-hidden");
    } else {
      this.periodSelectedTarget.value = null
      this.lessonTagsContainerTarget.classList.add("is-hidden")
      if (this.hasDiscountTagsContainerTarget)
        this.discountTagsContainerTarget.classList.add("is-hidden");
    }

    this.updateLessonsSelected()
    this.calculateTotalAmounts()
  }

  selectLesson(event) {
    event.preventDefault()

    const lessonEl = event.currentTarget
    if (!lessonEl.disabled) {
      lessonEl.classList.toggle("is-selected")

      this.updateLessonsSelected()
      this.calculateTotalAmounts()
    }
  }

  selectDiscount(event) {
    event.preventDefault()

    const discountEl = event.currentTarget
    this.discountTagTargets.forEach(tag => {
      if (tag === discountEl) {
        discountEl.classList.toggle("is-selected")
      } else {
        tag.classList.remove("is-selected")
        discountEl.blur()
      }
    })

    this.calculateTotalAmounts()
  }

  selectCustomDiscount(event) {
    const discountEl = event.currentTarget

    this.discountTagTargets.forEach(tag => tag.classList.remove("is-selected"))

    const value = parseFloat(discountEl.textContent)
    if (value >= 0 && value < 100) {
      discountEl.setAttribute("data-value", discountEl.textContent.trim())
      discountEl.classList.add("is-selected")
    } else {
      discountEl.textContent = "Custom"
      discountEl.setAttribute("data-value", "")
      discountEl.blur()
    }

    this.calculateTotalAmounts()
  }

  selectAccount(event) {
    event.preventDefault()

    const accountEl = event.currentTarget
    this.accountSelectedTarget.value = accountEl.dataset.value

    this.calculateTotalAmounts()
  }

  lessonRate(id, period, duration) {
    return this.weeklyHoursRate(period, duration, id)
  }

  weeklyHoursRate(period, duration, lessonId = null) {
    if (lessonId)
      lessonId = parseInt(lessonId);

    if (this.rates) {
      const rate = this.rates.filter(rate => rate.duration == parseFloat(duration) && rate.lessonId == lessonId).shift()

      if (rate)
        return rate[period];
    }
  }

  calculateTotalAmounts() {
    let feesAmount = 0
    this.feeTagTargets.forEach(tag =>{
      if (tag.classList.contains("is-selected"))
        feesAmount = feesAmount + parseFloat(tag.dataset.amount);
    })

    if (feesAmount > 0) {
      this.totalsFeesAmountTarget.innerHTML = feesAmount.toFixed(2)
      this.feesAmountTarget.value = feesAmount
    } else {
      this.totalsFeesAmountTarget.innerHTML = "-"
      this.feesAmountTarget.value = null
    }

    let lessonsAmount = 0, discountAmount = 0, missingRates = false, wrongDiscount = false
    const periodEl = document.querySelector(".period-tags .is-selected")
    if (periodEl) {
      let lessonRate = 0, hasRate = false

      const period = periodEl.dataset.period
      if (period == "week") {

        this.lessonTagTargets.forEach(tag => {
          if (tag.classList.contains("is-selected")) {
            hasRate = false

            lessonRate = this.lessonRate(tag.dataset.id, period, tag.dataset.duration) || this.weeklyHoursRate(period, tag.dataset.duration)
            if (lessonRate) {
              lessonsAmount = lessonsAmount + lessonRate
              hasRate = true
            } else {
              missingRates = true
            }

            tag.classList.toggle("has-rate", hasRate)
          }
        })

      } else {
        let totalDuration = 0, weeklyHoursRate = 0, hasCustomRate = []

        this.lessonTagTargets.forEach(tag => {
          if (tag.classList.contains("is-selected")) {
            lessonRate = this.lessonRate(tag.dataset.id, period, tag.dataset.duration)
            if (lessonRate) {
              lessonsAmount = lessonsAmount + lessonRate
              hasCustomRate.push(tag.dataset.id)
            } else {
              totalDuration = totalDuration + parseFloat(tag.dataset.duration)
            }
          }
        })

        if (totalDuration) {
          weeklyHoursRate = this.weeklyHoursRate(period, totalDuration)
          if (weeklyHoursRate) {
            lessonsAmount = lessonsAmount + weeklyHoursRate
            hasRate = true
          } else {
            hasRate = false
            missingRates = true
          }
        } else if(lessonsAmount === 0) {
          missingRates = true
        }

        this.lessonTagTargets.forEach(tag => {
          if (hasCustomRate.includes(tag.dataset.id)) {
            tag.classList.toggle("has-rate", true)
          } else {
            tag.classList.toggle("has-rate", hasRate)
          }
        })
      }

      if (lessonsAmount > 0) {
        this.totalsLessonsAmountTarget.innerHTML = lessonsAmount.toFixed(2)
        this.lessonsAmountTarget.value = lessonsAmount

        const discountEl = document.querySelector(".discount-tags .is-selected")
        if (discountEl) {
          if (discountEl.dataset.value.indexOf("%") >= 0) {
            discountAmount = parseFloat(lessonsAmount * parseFloat(discountEl.dataset.value) / 100)
          } else {
            discountAmount = parseFloat(discountEl.dataset.value)
          }

          if (discountAmount >= lessonsAmount)
            wrongDiscount = true;

          this.totalsDiscountAmountTarget.innerHTML = discountAmount.toFixed(2)
          this.discountAmountTarget.value = discountAmount
        } else {
          if (this.discountValueTarget) {
            discountAmount = parseFloat(lessonsAmount * parseFloat(this.discountValueTarget.value) / 100)
            this.totalsDiscountAmountTarget.innerHTML = discountAmount.toFixed(2)
            this.discountAmountTarget.value = discountAmount
          } else {
            this.totalsDiscountAmountTarget.innerHTML = "-"
            this.discountAmountTarget.value = null
          }
        }
      } else {
        this.totalsLessonsAmountTarget.innerHTML = "-"
        this.feesAmountTarget.value = null
      }
    } else {
      this.totalsLessonsAmountTarget.innerHTML = "-"
      this.lessonsAmountTarget.value = null
      this.totalsDiscountAmountTarget.innerHTML = "-"
      this.discountAmountTarget.value = null
    }

    if (feesAmount > 0 || lessonsAmount > 0) {
      const totalAmount = feesAmount + lessonsAmount - discountAmount
      if (totalAmount > 0) {
        this.totalsTotalAmountTarget.innerHTML = totalAmount.toFixed(2)
        this.totalAmountTarget.value = totalAmount.toFixed(2)
      } else {
        this.totalsTotalAmountTarget.innerHTML = "-"
        this.totalAmountTarget.value = null
      }

      const missingAccount = this.accountSelectedTarget.value == "" ? true : false

      if (!missingRates && !wrongDiscount && !missingAccount) {
        this.enablePayment()
      } else {
        this.disablePayment()
      }

      this.totalsContainerTarget.classList.remove("is-hidden")

    } else {
      this.totalsContainerTarget.classList.add("is-hidden")
      this.disablePayment()
    }

    this.totalsContainerTarget.classList.toggle("has-missing-rates", missingRates || wrongDiscount)
  }

  updateCategoriesSelected() {
    let categories = []
    this.feeTagTargets.forEach(tag => {
      if (tag.classList.contains("is-selected"))
        categories.push(tag.dataset.category);
    })

    if (this.lessonsSelectedTarget.value) {
      let lessons = JSON.parse(this.lessonsSelectedTarget.value)
      if (Array.isArray(lessons) && lessons.length > 0)
        categories.push("lessons");
    }

    this.categoriesSelectedTarget.value = JSON.stringify(categories)
  }

  updateLessonsSelected() {
    this.lessonsSelectedTarget.value = JSON.stringify(
      this.lessonTagTargets
        .map(tag => {
          if (tag.classList.contains("is-selected"))
            return tag.dataset.id;
        })
        .filter(v => v)
    )

    this.updateCategoriesSelected()
  }

  enablePayment() {
    this.paymentSubmitTarget.removeAttribute("disabled")

    if (this.hasCardElementTarget)
      this.cardContainerTarget.classList.remove("is-hidden");
  }

  disablePayment() {
    this.paymentSubmitTarget.setAttribute("disabled", true)

    if (this.hasCardElementTarget)
      this.cardContainerTarget.classList.add("is-hidden");
  }

}
